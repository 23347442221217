.site-footer {
  background:
          radial-gradient(black 15%, transparent 16%) 0 0,
          radial-gradient(black 15%, transparent 16%) 8px 8px,
          radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
          radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color:#282828;
  background-size:16px 16px;
  color: $color-white;
  .our-promise {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background: $color-white;
    color: $color-dark-grey;
    border-top: 5px solid $color-yellow;
    p {
      max-width: 1200px;
      margin: 0;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .bar {
    background: $color-yellow;
    color: $color-dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    h2 {
      margin: 0;
      text-align: center;
    }
  }
  .attention {
    margin: 50px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    a:not(.phone-link) {
      text-decoration: none;
      color: $color-white;
      margin: 0 auto;
      font-size: 52px;
      line-height: 68px;
      &:hover {
        color: $color-yellow;
      }
    }
    .phone-link {
      text-decoration: none;
    }
    .more-info {
      display: flex;
      justify-content: space-between;
      p {
        margin: 10px;
      }
    }
  }
  a {
    color: $color-white;
  }
  h3 {
    color: $color-white;
  }
  .footer-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .footer-menu {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      li {
        padding: 0 15px;
        border-right: 1px solid $color-white;
        text-align: center;
        &:last-of-type {
          border: 0;
        }
        a {
          text-decoration: none;
          &:hover {
            color: $color-yellow
          }
        }
      }
    }
  }
  .certs-container {
    margin-bottom: 50px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      li {
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 25px;
      }
    }
  }
}

.testimonials-button {
  position: fixed;
  background: $color-dark-grey;
  color: $color-white;
  right: -73px;
  top: 40%;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 15px;
  transform: rotate(-90deg);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  &:visited {
    color: $color-white;
  }
  &:hover {
    background: $color-white;
    color: $color-dark-grey;
  }
  i {
    margin-right: 10px;
  }
}

@media (max-width: $media-mobile-max) {
  .testimonials-button {
    display: none;
  }
  .site-footer {
    text-align: center;
    .attention {
      margin: 25px 0;
      a {
        font-size: 24px;
      }
      .more-info {
        flex-direction: column;
        padding-top: 15px;
        .separator {
          display: none;
        }
        p {
          margin: 0;
        }
      }
    }
    .footer-container {
      padding: 15px;
      flex-direction: column;
      .footer-menu {
        width: 100%;
        ul {
          flex-direction: column;
          li {
            border: 0;
          }
        }
      }
      .certs-container {
        ul {
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          li {
            width: 22%;
            margin-top: 25px;
            padding: 0;
            flex-grow: initial;
            flex-basis: initial;
          }
        }
      }
    }
  }
}