.content-container {
  h1, h2, h3, h4, h5, h6 {
    font-family: $font-heading;
    color: $color-dark-grey;
    margin: 50px 0 25px 0;
    text-transform: uppercase;
  }

  h1 {
    font-size: 42px;
    line-height: 52px;
  }

  h2 {
    font-size: 32px;
    line-height: 42px;
    text-transform: uppercase;
  }

  h3 {
    font-size: 28px;
    line-height: 36px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 22px;
    line-height: 30px;
  }

  & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
    margin-top: 0;
  }

  img {
    border-bottom: 5px solid $color-yellow;
    margin-bottom: 1.5em;
  }

  .wp-caption {
    background: $color-blue;
    color: $color-dark-grey;
    padding: 5px;
    img {
      margin-bottom: .5em;
    }
  }

  .wp-caption .wp-caption-text {
    font-size: 12px;
    margin-top: 0;
    color: $color-dark-grey;
    line-height: 16px;
  }

  .gallery-new {
    margin: 50px 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        width: 30%;
        text-align: center;
        padding: 10px;
        margin: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          border: 0 !important;
          margin: 0;
          max-height: 120px;
          height: auto;
        }
        //&:nth-child(3n+1) {
        //  text-align: left!important;
        //}
        //&:nth-child(3n+2) {
        //  text-align: center!important;
        //}
        //&:nth-child(3n+3) {
        //  text-align: right!important;
        //}
      }
    }
  }

  .elementor {
    h4 {
      margin-top: 0;
    }
  }

  .rl-gallery-container {
    margin-top: 50px;
  }

  @media (max-width: $media-mobile-max) {
    img {
      float: none;
      display: block;
      max-width: 100%;
      margin: 0 auto 1.5em auto;
    }
    h1 {
      font-size: 32px;
      line-height: 42px;
    }

    h2 {
      font-size: 24px;
      line-height: 30px;
      text-transform: uppercase;
    }

    h3 {
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
    }

    h4 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}