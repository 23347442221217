.services {
  .services-header {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 5px;
        &.current_page_item {
          a {
            background: $color-dark-grey;
            color: $color-white;
          }
        }
        a {
          text-decoration: none;
          padding: 5px 10px;
          border: 1px solid $color-dark-grey;
          color: $color-dark-grey;
          &:hover {
            background: $color-dark-grey;
            color: $color-white;
          }
        }
      }
    }
  }
}

@media (max-width: $media-mobile-max) {
  .services {
    .services-header {
      ul {
        justify-content: space-between;
        align-items: flex-start;
        li {
          width: 45%;
          margin: 5px 0;
          display: flex;
          text-align: center;
          a {
            width: 100%;
          }
        }
      }
    }
  }
}