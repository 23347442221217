.contact {
  .contact-container {
    display: flex;
    padding: 0;
    .left, .right {
      width: 50%;
    }
    .right {
      background: #d5e4f1;
      padding: 25px;
      p {
        margin-bottom: 0;
      }
      form {
        input, textarea {
          background: transparent;
          padding: 10px;
          border: 0;
          border-bottom: 2px solid $color-dark-grey;
          border-radius: 0;
          appearance: none;
          -webkit-appearance: none;
          margin-bottom: 25px;
          color: $color-dark-grey;
          &:focus {
            outline: 0;
            background: #fff;
          }
          &::placeholder {
            color: $color-dark-grey;
          }
        }
        input[type="submit"] {
          border: 0;
          background: $color-dark-grey;
          color: $color-yellow;
          text-transform: uppercase;
          cursor: pointer;
          font-size: 20px;
          margin-bottom: 0;
        }
      }
      div.wpcf7-response-output {
        background: $color-white;
      }
      div.wpcf7-response-output {
        margin: 10px 0 25px 0;
      }
    }
    .left {
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      flex-direction: column;
      .img-container {
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        background-image: url('../img/hello.png');
        height: 300px;
      }
      .text-container {
        flex-grow: 2;
        background: rgba(255,255,255,.95);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 50px 50px 50px;
        flex-direction: column;
        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: $media-tablet-max) {
  .contact {
    .container-full {
      .left {
        display: none;
      }
      .right {
        width: 100%;
        padding: 25px;
        h2 {
          text-align: center;
        }
        input {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $media-mobile-max) {

}