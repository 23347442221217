.home {
  .home-slider {
    position: relative;
    max-height: calc(100vh - 168px);
    overflow: hidden;
    .main-cta {
      position: absolute;
      z-index: 8;
      width: 100%;
      max-width: 1920px;
      text-align: center;
      padding: 50px;
      color: #fff;
      background: rgba(0,0,0,.25);
      height: 100%;
      h1 {
        color: #fff;
        text-shadow: 0 0 20px $color-dark-grey;
        text-transform: uppercase;
      }
      p {
        text-shadow: 0 0 3px $color-dark-grey;
        font-size: 20px;
        text-transform: uppercase;
        max-width: 800px;
        margin: 0 auto;
      }
      a {
        margin-top: 25px;
      }
      .go-down {
        margin-top: 50px;
        a {
          color: #fff;
          font-size: 52px;
          text-shadow: none;
        }
      }
    }
  }
  .section {
    margin: 50px 0;
  }
  .our-services {
    margin-bottom: 0;
    .container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .service {
        width: 33.3333%;
        padding: 25px 50px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 50px;
        i {
          font-size: 34px;
        }
        h4 {
          margin-top: 10px;
          text-transform: uppercase;
          min-height: 60px;
        }
        .btn {
          margin-top: auto;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .contact-us {
    background: $color-grey;
    .container-full {
      display: flex;
      padding: 0;
      .left, .right {
        width: 50%;
      }
      .right {
        background: #d5e4f1;
        padding: 25px;
        p {
          margin-bottom: 0;
        }
        form {
          input, textarea {
            background: transparent;
            padding: 10px;
            border: 0;
            border-bottom: 2px solid $color-dark-grey;
            border-radius: 0;
            appearance: none;
            -webkit-appearance: none;
            margin-bottom: 25px;
            color: $color-dark-grey;
            &:focus {
              outline: 0;
              background: #fff;
            }
            &::placeholder {
              color: $color-dark-grey;
            }
          }
          input[type="submit"] {
            border: 0;
            background: $color-dark-grey;
            color: $color-yellow;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 20px;
            margin-bottom: 0;
          }
        }
        div.wpcf7-response-output {
          background: $color-white;
        }
        div.wpcf7-response-output {
          margin: 10px 0 25px 0;
        }
      }
      .left {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        flex-direction: column;
        .img-container {
          background-repeat: no-repeat;
          background-position: right;
          background-size: cover;
          background-image: url('../img/hello.png');
          height: 300px;
        }
        .text-container {
          flex-grow: 2;
          background: rgba(255,255,255,.95);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 25px 50px 50px 50px;
          flex-direction: column;
          p:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .about-us {
    .container {
      text-align: center;
      h2 {
        text-align: center;
      }
      p {
        max-width: 700px;
        margin: 0 auto;
      }
      img {
        margin: 25px 0;
        border-bottom: 10px solid $color-yellow;
      }
      a {
        display: block;
        max-width: 200px;
        margin: 0 auto;
      }
    }
  }
  .our-work {
    background-color: $color-grey;
    background: url("../img/roof-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    .container {
      display: flex;
      justify-content: space-between;
      .left, .right {
        width: 50%;
        padding: 0 25px;
      }
      .left {

      }
      .right {
        .rl-gallery-item {
          //padding: 0 5px;
        }
      }
    }
  }
  .testimonials {
    position: relative;
    .quote-up {
      position: absolute;
      font-size: 100px;
      top: 0;
      left: 50px;
      opacity: .05;
    }
    .quote-down {
      position: absolute;
      font-size: 100px;
      bottom: 0;
      right: 50px;
      opacity: .05;
    }
    .testimonial_rotator_wrap {
      padding: 0 50px!important;
      margin: 0!important;
    }
  }
}

@media (max-width: $media-tablet-max) {
  .home {
    .section {
      margin: 25px 0;
    }
    .home-slider {
      .main-cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 34px;
          line-height: 42px;
        }
        .go-down {
          margin-top: 20px;
        }
      }
    }
    .our-services {
      .container {
        flex-wrap: wrap;
        .service {
          min-width: 200px;
          padding: 25px;
        }
      }
    }
    .contact-us {
      .container-full {
        .left {
          display: none;
        }
        .right {
          width: 100%;
          padding: 25px;
          h2 {
            text-align: center;
          }
          input {
            width: 100%;
          }
        }
      }
    }
    .our-work {
      background: #94A0AB;
      padding: 15px;
      .container {
        flex-direction: column;
        .left, .right {
          width: 100%;
        }
        .left {
          text-align: center;
          margin-bottom: 50px;
          padding: 0;
          .btn {
            margin-top: 25px;
          }
        }
        .right {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: $media-mobile-max) {
  .home {
    .section {
      margin: 15px 0;
    }
    .home-slider {
      .main-cta {
        padding: 15px;
        h1 {
          font-size: 24px;
          line-height: 32px;
        }
        p {
          display: none;
        }
        .go-down {
          display: none;
        }
        .btn {
          margin-top: 0;
        }
      }
    }
    .our-services {
      .container {
        flex-wrap: wrap;
        .service {
          width: 100%;
          min-width: 100%;
          padding: 15px;
          margin-bottom: 25px;
          h4 {
            min-height: auto;
          }
        }
      }
    }
    .testimonials {
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}