@import url('https://fonts.googleapis.com/css?family=Chivo|Overpass:400,400i,700,700i');

//Colors

$color-main: #52707F;
$color-grey: #94A0AB;
$color-blue: #5d8db5;
$color-white: #FFFFFF;
$color-yellow: #f0c417;
$color-dark-grey: #222222;
$color-dark-blue: #0a3c71;

//Fonts

$font-heading: 'Chivo', sans-serif;
$font-body: 'Overpass', sans-serif;

//Breakpoints

$media-mobile-max: 768px !default;
$media-tablet-max: 1000px !default;