.page, .single {
  .area-container {
    display: flex;
    .sidebar {
      min-width: 300px;
      width: 350px;
      padding-left: 50px;
    }
  }
  .featured-image {
    height: 270px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom: 10px solid $color-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5d8db5;
    &.no-border {
      border: 0;
      height: 200px;
    }
    p.hero {
      display: flex;
      color: $color-white;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,.25);
      text-transform: uppercase;
      font-size: 72px;
      line-height: 90px;
      margin: 0;
      font-weight: bold;
      padding: 10px 20px;
      text-align: center;
      span {
        //text-shadow: 0 0 20px $color-dark-grey;
      }
    }
  }
  .content-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 0 25px 0;
  }
  .content-container-wide {
    max-width: 1400px;
    margin: 0 auto;
    padding: 50px 0 25px 0;
  }
  .testimonials {
    position: relative;
    .quote-up {
      position: absolute;
      font-size: 100px;
      top: 0;
      left: 50px;
      opacity: .05;
    }
    .quote-down {
      position: absolute;
      font-size: 100px;
      bottom: 0;
      right: 50px;
      opacity: .05;
    }
    .testimonial_rotator_wrap {
      padding: 0 50px!important;
      margin: 0!important;
    }
  }
}

.rl-gallery-container {
  overflow: hidden;
}

.testimonial_rotator_wrap {
  margin: 0;
  padding: 0;
}

@media (max-width: $media-mobile-max) {
  .page, .single {
    .area-container {
      display: flex;
      flex-direction: column;
      .sidebar {
        min-width: 300px;
        width: 100%;
        padding-left: 0;
      }
    }
    .featured-image {
      p.hero {
        font-size: 42px;
        text-align: center;
        line-height: 52px;
      }
    }
  }
}