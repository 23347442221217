* {
  box-sizing: border-box;
}

html, body {
  font-family: $font-body;
  font-size: 16px;
  line-height: 28px;
  color: $color-dark-grey;
  background: $color-white;
}

body {
  &.no-scroll {
    overflow: hidden;
  }
  &.floating {
    padding-top: 48px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-heading;
  color: $color-dark-grey;
  margin-top: 0;
}

h1 {
  font-size: 42px;
  line-height: 52px;
}

h2 {
  font-size: 32px;
  line-height: 42px;
  text-transform: uppercase;
}

h3 {
  font-size: 28px;
  line-height: 36px;
  text-transform: uppercase;
}

h4 {
  font-size: 22px;
  line-height: 30px;
}

a, a:visited {
  text-decoration: underline;
  color: $color-main;
  &:hover {
    text-decoration: none;
  }
}

a.btn {
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
  color: #fff!important;
  background: $color-yellow;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  &:active, &:hover {
    color: $color-white!important;
    background: $color-blue;
  }
}

p {
  margin-top: 0;
}

img {
  max-width: 100%;
}

.map-container{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
}