.blog-container {
  display: flex;
  .sidebar {
    min-width: 300px;
    width: 350px;
    padding-left: 50px;
  }
  .blog-post-container {
    margin-bottom: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid $color-grey;
  }
}

@media (max-width: $media-mobile-max) {
  .blog-container {
    flex-direction: column;
    .sidebar {
      width: 100%;
      padding: 0;
    }
  }
}