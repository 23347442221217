.site-header {
  display: flex;
  flex-direction: column;
  .branding-container {
    display: flex;
    align-items: center;
    .img-container {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
      }
      .mobile-trigger {
        display: none;
      }
      img {
        max-width: 400px;
      }
    }
  }
  .header-branding {
    height: 120px;
    display: flex;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      .header-phone {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          margin: 0;
          font-weight: bold;
          font-size: 20px;
          text-transform: uppercase;
          line-height: 24px;
        }
        .tel {
          color: $color-yellow;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 30px;
          padding: 5px 0;
          transition: color .1s ease-in-out;
          &:hover {
            color: $color-main;
          }
          i {
            margin-right: 5px;
          }
        }
        .email {
          font-size: 14px;
          padding: 0;
          color: $color-dark-grey;
          line-height: 18px;
        }
        .location {
          font-size: 14px;
          text-transform: initial;
          font-weight: normal;
          line-height: 18px;
        }
      }
    }
  }
  .main-navigation {
    background: $color-dark-grey;
    height: 48px;
    display: flex;
    position: relative;
    z-index: 9;
    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
    .container {
      padding: 0;
    }
    .menu {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      li {
        display: flex;
        &:hover {
          background-color: $color-dark-blue;
          a {
            color: $color-white;
          }
        }
        a {
          color: $color-white;
          padding: 10px 15px;
          //transition: background-color .1s ease-in-out;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: bold;
          &:hover {
            background-color: $color-dark-blue;
          }
        }
        ul {
          display: none;
        }
        &.menu-item-has-children {
          position: relative;
          & > a {
            padding-right: 25px;
          }
          &::after {
            content: '▾';
            position: absolute;
            right: 8px;
            color: $color-white;
            height: 100%;
            display: flex;
            align-items: center;
          }
          &:hover {
            &::after {
              color: $color-white;
            }
            ul {
              display: flex;
              position: absolute;
              flex-direction: column;
              top: 48px;
              left: 0;
              width: 250px;
              margin: 0;
              padding: 0;
              li {
                a {
                  background-color: $color-dark-blue;
                  width: 100%;
                  color: $color-white;
                  &:hover {
                    color: $color-white;
                    background-color: $color-blue;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $media-mobile-max) {
  .site-header {
    .branding-container {
      .img-container {
        margin-bottom: 10px;
        justify-content: space-between;
        width: 100%;
        .mobile-trigger {
          align-self: center;
          font-size: 30px;
          cursor: pointer;
          display: flex;
          margin-right: 5px;
        }
        img {
          max-width: 75%;
        }
      }
    }
    .header-branding {
      height: auto;
      .container {
        flex-direction: column;
        padding: 10px;
        .header-phone {
          justify-content: center;
          text-align: center;
          flex-direction: row;
          p {
            font-size: 14px;
            margin-right: 10px;
          }
          .tel {
            font-size: 20px;
            padding: 0;
          }
          .email {
            display: none;
          }
          .location {
            display: none;
          }
        }
      }
    }
    .main-navigation {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 60px 0 25px 0;
      overflow-y: auto;
      .mobile-close {
        position: absolute;
        top: 15px;
        right: 13px;
        font-size: 30px;
        color: $color-yellow;
      }
      .menu {
        flex-direction: column;
        li {
          a {
            width: 100%;

          }
          &.menu-item-has-children {
            flex-direction: column;
            &.open {
              background-color: $color-dark-blue;
              color: $color-white;
              ul {
                color: $color-white;
                li {
                  color: $color-white;
                  a {
                    color: $color-white;
                  }
                }
              }
            }
            ul {
              margin: 0;
              padding-left: 25px;
              li {
                background-color: $color-dark-blue;
                width: 100%;
                color: $color-dark-grey;
              }
            }
            &::after {
              align-content: flex-start;
              height: auto;
              top: 10px;
            }
            &:hover {
              &::after {
                color: $color-white;
              }
              ul {
                display: none;
                position: relative;
                flex-direction: column;
                top: auto;
                left: auto;
                width: 100%;
                margin: 0;
                padding-left: 25px;
                li {
                  a {
                    background-color: $color-yellow;
                    width: 100%;
                    color: $color-dark-grey;
                    &:hover {
                      color: $color-dark-grey;
                      background-color: $color-yellow;
                    }
                  }
                }
              }
            }
            &.open {
              a {
                color: $color-white;
                padding: 5px 10px;
              }
              & > a {
                padding: 10px 15px;
              }
              &::after {
                color: $color-white;
                content: '▴';
              }
              ul {
                position: relative;
                flex-direction: column;
                top: auto;
                left: auto;
                width: 100%;
                margin: 0;
                padding-left: 25px;
                li {
                  a {
                    background-color: $color-dark-blue;
                    width: 100%;
                    color: $color-white;
                    text-transform: capitalize;
                    &:hover {
                      color: $color-dark-blue;
                      background-color: $color-white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}