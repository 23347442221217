.container {
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: $media-mobile-max) {
  .container {
    padding: 10px;
    width: 100%;
    max-width: none;
    margin: 0 auto;
  }
}